import React, {useRef} from 'react';
import {connect, useSelector} from "react-redux";
import {history} from "../../../../../history";
import {useTranslation} from "react-i18next";

const SendEmail = (props) => {
    const { t, i18n } = useTranslation();

    const {title, description, classBtn, currentRef, footer, btnLabel} = props;
    const user = useSelector(state => state.authorizedUser.user);
    const thisRef = useRef(null);
    const inputRef = currentRef ? currentRef : thisRef;
    return (
        <div className={`form form--sent-email ${footer ? 'form--footer' : ''}`}>
            {title && <p className='form__title'>{title}</p>}
            <div className="form__content">
                {user.email === '' && (<>
                    <input className='form-control' type="email" placeholder={t('Ваш email')} ref={inputRef}/>
                    <button className={`btn btn-primary ${classBtn}`} onClick={() => {
                        history.push('/sign-up', {
                            email: inputRef.current && inputRef.current.value
                        })
                    }}>{btnLabel ? btnLabel : t('Начать')}
                    </button>
                </>)}
                {user.email !== '' && <button className={`btn btn-primary ${classBtn}`} onClick={() => {
                    history.push('/dashboard')
                }}>{t("Дашборд")}</button>}
            </div>
            {description && <span className='form__description'>{t("Скидка 50% на первый запуск!")}</span>}
        </div>
    )
}


export default SendEmail;