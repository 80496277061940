const initialState = {
    users_list:[],
    user: {
        id: 0,
        email: "",
        fname: "",
        lname: "",
        reviews: 0,
        company: {
            name: '',
            address: '',
            zip: '',
            city: '',
            country: ''
        },
        tips: null,
        lang: null
    },
    guide: {
        dashboard: false,
        products: false,
        functionality: false,
        orders: false
    },
    consultation_success: false,
    phone: null
}

const authorizedUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_USERS":
            return {...state, users_list: action.users};
        case "FETCH_AUTHORIZED_USER_INFO":
            return {...state, user: action.user, phone: null};
        case "SET_USER_TIPS":
            return {...state, user: action.user, guide: {...state.guide, [action.guide]: true}};
        case "CHANGE_USER_LANGUAGE":
            return {...state, user: {...state.user, lang: action.payload}};
        case "RESET_USER_OBJECT":
            return {...state, user: initialState.user};
        case "USER_UNSUBSCRIBE":
            return state;
        case "RESET_USER_GUIDE_DATA":
            return {...state, guide: {...state.guide, [action.payload]: false}};
        case "SEND_CONSULTATION":
            return {...state, consultation_success: true};
        case "SEND_BEFORE_REGISTRATION":{
            return {...state, phone: action.phone};
        }
        case "RESET_REGISTRATION_PHONE":{
            return {...state, phone: null};
        }
        default:
            return state
    }
};

export default authorizedUserReducer
