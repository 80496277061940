import axios from "axios"
import {setFetchMessage} from "../notification";
import {getLoggedUserInfo} from "../user";

export const ACTIONS = {
    GET_ALL_GAMPAIGNS: "GET_ALL_GAMPAIGNS_ACTIVE",
    SET_IS_FETCHING: "SET_IS_CAMPAIGN_FETCHING_ACTIVE",
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE_ACTIVE",
    NO_DATA: "NO_GAMPAIGNS_DATA_ACTIVE",
    NEW_CAMPAIGN: "NEW_CAMPAIGN_ACTIVE",
    RESET_NEW_CAMPAIGN: "RESET_NEW_CAMPAIGN_ACTIVE",
    REFUND_RESULT: "REFUND_RESULT",
    RESET_REFUNDS: "RESET_REFUNDS"
}

export const getAllCampaigns = (status = 'active,paused', currentPage = 1, perPage = 5, asin = "", market = "de", marketplace = 'prom') => {
    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .get(
                `/api/product/list/${status}`,
                {params: {per_page: perPage, page: currentPage, asin: asin, market: market, marketplace: marketplace}}
            )
            .then(response => {
                dispatch(setCurrentPage(currentPage))
                dispatch({
                    type: ACTIONS.GET_ALL_GAMPAIGNS,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.NO_DATA,
                });
                if (error.response.status != 400)
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
            })


    }
};

export const refund = (product_id, callback = () => {}) => {
    return dispatch => {
        axios
            .post(`/api/product/${product_id}/refund`)
            .then(response => {
                dispatch({
                    type: ACTIONS.REFUND_RESULT,
                    payload: response.data.data
                })
                dispatch(getLoggedUserInfo())
                callback();

            })
            .catch(error => {
                if(error.response?.data){
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
                }
            })
    }
};

export const resetRefunds = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_REFUNDS,
        })
    }
};

export const setIsFetching = (bool) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_FETCHING,
            payload: bool,
        });
    }
};

export const setCurrentPage = (page) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_CURRENT_PAGE,
            payload: page,
        });
    }
};

export const resetNewCampaign = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_NEW_CAMPAIGN,
        });
    }
};

export const newCampaign = (data) => {
    return dispatch => {
        axios
            .post('/api/campaign', data)
            .then(response => {
                dispatch({
                    type: ACTIONS.NEW_CAMPAIGN
                })
            })
    }
}
