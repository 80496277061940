import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        fallbackLng: 'ru',
        detection: {
            lookup(options) {
                console.log(options)
                // options -> are passed in options
                return 'ru';
            },
            order: ['localStorage', "cookies"],
            caches: ['localStorage', "cookies"],
            excludeCacheFor: ['cimode'],
        },
        debug: false,
        backend: {
            loadPath: '/locales/{{ns}}-{{lng}}.json'
        },
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            wait: true
        }
    });

export default i18n;