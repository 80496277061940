import {ACTIONS} from "../../actions/products";


const initialState = {
    products: {
        data: []
    },
    sellers: [],
    isFetching: false,
    successCreate: null,
    hasError: false,
    currentPage: 1,
    perPage: 5,
    filter: null,
    firstProduct: false,
    createdProduct: null,
    product_non_taken_jobs:0,
    can_create_manual:false
}

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_PRODUCT_NOT_TAKEN_JOBS:
            return {
                ...state,
                product_non_taken_jobs: action.payload,
            };
        case ACTIONS.GET_ALL_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                isFetching: false,
            };
        case ACTIONS.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload,
            };
        case ACTIONS.CAN_CREATE_MANUAL_PRODUCT:
            return {
                ...state,
                can_create_manual: true
            };
        case ACTIONS.SET_CURRENT_PROD_PAGE:
            return {
                ...state,
                currentPage: action.payload
            };
        case ACTIONS.GET_AMAZON_PRODUCT_SELLERS:
            return {
                ...state,
                sellers: action.payload.data,
                isFetching: false,
            };
        case ACTIONS.CLEAR_SELLER:
            return {
                ...state,
                sellers: action.payload,
                isFetching: false,
            };
        case ACTIONS.RESET_ERROR_PRODUCT:
            return {
                ...state,
                hasError: false
            };
        case ACTIONS.SUCCESS_CREATE_PRODUCT:
            return {
                ...state,
                isFetching: false,
                hasError: false,
                successCreate: true,
                firstProduct: action.payload.first_product,
                createdProduct: action.payload.product
            };
        case ACTIONS.ERROR_CREATE_PRODUCT:
            return {
                ...state,
                isFetching: false,
                hasError: true
            };
        case ACTIONS.RESET_SELLERS_DATA:
            return {
                ...state,
                sellers: [],
                can_create_manual: false,
                successCreate: false,
            };
        default:
            return state
    }
};

export default productsReducer
