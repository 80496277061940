import React, { useRef } from 'react';
import Image from "../image/Image";
import Nav from "react-bootstrap/Nav"
import SendEmail from "../forms/SendEmail";
import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SendPhone from "../forms/SendPhone";

const Footer = (props) => {
    const {t, i18n} = useTranslation();

    return (
        <footer className="footer">
            <div className="footer__bg">
            <div className="footer__top">
                <Container>
                    <Row className="align-items-center">
                        <Col sm={ 4 } md={ 4 } lg={ 2 } className={ 'mx-auto text-center' }>
                            <div className="navbar-brand">
                                <Image slug='logo.png' alt='logo' className='logo'/>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </div>

            <div className="copyright">
                <div className="copyright__column">© 2021 - 2022 BestLaunch.com</div>
                <div className="copyright__column">
                    <a className='link' href="mailto:clients@bestlaunch.com.ua">clients@bestlaunch.com.ua</a>
                </div>
                <div className="copyright__column">
                    <a href="tel:380985417317" className='link'>+38 (098) 54 17 317</a>
                    {/*<div className="links">*/}
                    {/*    /!*<NavLink to='/impressum' className='link'>{ t('Imprint') }</NavLink>*!/*/}
                    {/*    /!*<NavLink to='/terms-and-conditions' className='link'>{ t('Условия и положения') }</NavLink>*!/*/}
                    {/*</div>*/}
                </div>
            </div>
            </div>
        </footer>
    )
};

export default Footer;