import {ACTIONS} from "../../actions/jobs";


const initialState = {
    jobs: {},
    isFetching: false,
    fetching_product_id: 0,
    fetching_job_id: 0,
    job_fetching: false,
    job_update: false,
}

const jobsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ALL_JOBS:
            state.jobs[action.product_id] = action.payload;
            return {
                ...state,
                jobs: state.jobs,
                isFetching: false,
                fetching_product_id: action.product_id
            };
        case ACTIONS.RESET_JOB_STATUS:
            return {
                ...state,
                job_update: false
            };
        case ACTIONS.JOB_UPDATED:
            return {
                ...state,
                job_fetching: false,
                job_update: action.payload
            };
        case ACTIONS.NO_JOB_GIVEN:
            return {
                ...state,
            };
        case ACTIONS.SET_IS_JOB_FETCHING:
            return {
                ...state,
                job_fetching: action.payload,
            };
        case ACTIONS.SET_IS_FETCHING:
            return {
                ...state,
                isFetching: true,
                fetching_product_id: action.payload
            };
        default:
            return state
    }
};

export default jobsReducer
