import { history }         from "@src/history";
import axios               from "axios";
import { apiErrorHandler } from '@utils/apiErrorHandler';
import { setFetchMessage } from "../notification";
import i18next from "i18next";

function loginSuccessful(response, dispatch) {
    let user = response.data.data.user;

    dispatch({
        type: "FETCH_AUTHORIZED_USER_INFO",
        user: user
    });

    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('access_token', response.data.data.access_token);

    axios.defaults.headers.common = {Authorization: `Bearer ${response.data.data.access_token}`};

    let redirect = '/dashboard';
    if (user.role.name === 'superAdmin') {
        redirect = "/admin/dashboard";
    } else if (user.role.name === 'adminManager') {
        redirect = "/admin/clients-and-ads";
    }
    history.push(redirect);
}


export const loginWithSwitchToClientToken = token => {
    return dispatch => {
        axios.post(`/api/auth/switch-to-client/${token}`)
             .then(response => {
                 if (response.data) {
                     loginSuccessful(response, dispatch);
                 }
             }).catch(errors => {
            console.log(errors);
        });
    };
};

export const loginWithJWTReset = user => {
    return dispatch => {
        dispatch({
            type: "RESET_LOGIN"
        });
    };
};


export const loginWithJWT = user => {
    return dispatch => {
        axios
            .post(`/api/auth/login`, {
                email   : user.email,
                password: user.password,
            })
            .then(response => {
                if (response.data) {
                    loginSuccessful(response, dispatch);
                }
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
            });
    };
};

export const logoutWithJWT = (withOutRedirect = false) => {
    return dispatch => {
        axios
            .post(`/api/auth/logout`, {})
            .then(response => {
                // console.log(response);
                localStorage.removeItem('user');
                localStorage.removeItem('access_token');
                localStorage.clear();
                dispatch({
                    type: 'RESET_USER_OBJECT'
                });
                if(!withOutRedirect){
                    history.push('/');
                }
            })
            .catch(err => {
                // console.warn(err);
                localStorage.removeItem('user');
                localStorage.removeItem('access_token');
                localStorage.clear();
                history.push('/');
                dispatch(apiErrorHandler(err));
            });
    };
};

export const changeRole = role => {
    return dispatch => dispatch({type: "CHANGE_ROLE", userRole: role});
};

export const restorePassword = email => {

    return dispatch => {
        axios
            .post(`/api/auth/reset-password/link`, {
                email: email.email,
            })
            .then(response => {

                if (response.data) {
                    dispatch({
                        type : "RESTORE_PASSWORD",
                        reset: {email: email.email}
                    });
                    if(response.data.status == 'success' && response.data.code == 'password_reset_email_sent'){
                        dispatch(setFetchMessage(i18next.t('Ссылка для изменения пароля била отправилена вам на почту!'), 'success'))
                    }else{
                        dispatch(setFetchMessage(i18next.t('Что-то пошло не так. Пожалуйста, повторите попытку позже!'), 'warning'))
                    }
                    history.push('/');
                }
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
            });
    };
};

export const resetPassword = (data) => {

    return dispatch => {
        dispatch(setProcessing(true))
        axios
            .post(`/api/auth/reset-password/token`, data)
            .then(response => {
                if (response.data) {
                    if(response.data.status == 'success'){
                        dispatch({
                            type : "RESET_PASSWORD",
                            reset: response.data
                        });
                        history.push('/login');
                    }else{
                        dispatch(resetPasswordError(response.data))
                    }

                }
                dispatch(setProcessing(false))
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
                dispatch(setProcessing(false))

            });
    };
};

export const setProcessing = (proc) => {
    return dispatch => {
        dispatch({
            type : "RESET_PASSWORD_PROCESSING",
            payload: proc
        });
    };

};

export const resetPasswordError = (data) => {
    return dispatch => {
        dispatch({
            type : "RESET_PASSWORD_ERROR",
            message: data.message
        });
    };

};

export const signUp = user => {
    let phone = user.phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "");
    return dispatch => {

        let data = {
            fname                : user.fname,
            lname                : user.lname,
            email                : user.email,
            phone                : phone,
            password             : user.password,
            password_confirmation: user.password_confirmation,
        }

        if(user?.partner_id){
            data.partner_id = user?.partner_id;
        }
        dispatch({
            type: "RESET_LOGIN"
        })
        axios
            .post(`/api/auth/signup`, data)
            .then(response => {
                if (response.data) {
                    let user = response.data.data.user;

                    dispatch({
                        type: "FETCH_AUTHORIZED_USER_INFO",
                        user: user
                    });



                    localStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('access_token', response.data.data.access_token);

                    axios.defaults.headers.common = {Authorization: `Bearer ${response.data.data.access_token}`};

                    let redirect = '/registration_success';

                    history.push(redirect);
                }
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
            });
    };
};

export const resetUserPhone = () => {
    return dispatch => dispatch({
        type: "RESET_REGISTRATION_PHONE",
    });
}

export const sendPhoneBeforeSignUp = (data) => {
    let phone = data.phone.replace(/([ -.*+?^=!:${}()|\[\]\/\\])/g, "").replace(" ", "");
    if(phone.length < 12){
        return dispatch => {
            dispatch(setFetchMessage('Пожалуйста, введите корректный номер телефона!', 'warning'))
        }
    }
    return dispatch => {
        axios
            .post(`/api/lead/add`, {
                phone: phone,
                type: data.type
            })
            .then(response => {
                if (response.data) {
                    if(data.type == 'consultation'){
                        dispatch({
                            type: "SEND_CONSULTATION",
                            payload: response.data,
                        });
                    }else if(data.type == 'registration'){
                        dispatch({
                            type: "SEND_BEFORE_REGISTRATION",
                            payload: response.data,
                            phone: data.phone
                        });
                    }
                }
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
                if(err.response?.data?.code == 'phone_duplicated'){
                    dispatch(setFetchMessage('Вы уже оставили заявку на консультацию', 'warning'))
                }else{
                    dispatch(setFetchMessage(null, 'warning'))
                }
            });
    };
}