import axios from "axios"
import { setFetchMessage } from "../notification";
import { setCurrentPage } from "../campaigns/finished";

export const ACTIONS = {
    GET_ALL_JOBS: "GET_ALL_JOBS",
    SET_IS_FETCHING: "SET_IS_JOBS_FETCHING",
    SET_IS_JOB_FETCHING: "SET_IS_JOB_FETCHING",
    JOB_UPDATED: "JOB_UPDATED",
    NO_JOB_GIVEN: "NO_JOB_GIVEN",
    RESET_JOB_STATUS: "RESET_JOB_STATUS",
}

export const getAllJobs = (product_id, type) => {
    let url = `/api/product/${product_id}/jobs/`;
    if(type === 'finished'){
        url+='completed'
    }else{
        url+='active'
    }
    return dispatch => {
        dispatch(setIsFetching(product_id))
        axios
            .get(url)
            .then(response => {
                console.log(response)
                dispatch({
                    type: ACTIONS.GET_ALL_JOBS,
                    payload: response.data, //Object.keys(response.data.reviewjobs).map((key) => response.data.reviewjobs[key]),
                    product_id: product_id,
                });
            })
            .catch(error => {
                dispatch({
                    type: ACTIONS.GET_ALL_JOBS,
                    payload: [],
                    product_id: product_id
                });
            })
        // const resp = await axios.get()
        
    }
};


export const confirmJob = (data, callback = () => {}) => {
    return dispatch => {

        if(!data.id){
            dispatch(setFetchMessage('Не указан отзыв', 'error'));
            dispatch({
                type: ACTIONS.NO_JOB_GIVEN
            });
            return;
        }
        dispatch(setIsJobFetching(true))
        dispatch(resetJobUpdateStatus())
        axios
            .put('/api/job/change-status', data)
            .then(response => {
                if(response.data.status == '200'){
                    dispatch(setFetchMessage(response.data.message, 'success'))
                    dispatch({
                        type: ACTIONS.JOB_UPDATED,
                        payload: data.id,
                    });
                    callback();
                }
            })
            .catch(error => {
                console.log(error)
                if(error.response.status != 400){
                    dispatch(setIsJobFetching(false))
                    dispatch(setFetchMessage(error.response.data.message, 'error'));
                }
            })
        // const resp = await axios.get()

    }
};

export const resetJobUpdateStatus = () => {
    return dispatch => {
        dispatch({
            type: ACTIONS.RESET_JOB_STATUS,
        });
    }
}

export const setIsFetching = (bool) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_FETCHING,
            payload: bool,
        });
    }
};

export const setIsJobFetching = (bool) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_JOB_FETCHING,
            payload: bool,
        });
    }
};


