import axios from "axios"
import {apiErrorHandler} from "@utils/apiErrorHandler";
import {history} from "@src/history"
import notices from "../../../utils/notices";
import {setFetchMessage} from "../notification";
import logo from "@assets/img/logo/imh_pink.webp";
import { logoutWithJWT } from "../auth/loginActions";

export const getLoggedUserInfo = (require_authorized) => {
    return dispatch => {
        axios
            .get(`/api/me`)
            .then(response => {
                // console.log(response)
                // console.log(response.data.data)
                //temporary check untill server side remove status from error and return an actual error
                if(response.data.status === 401){
                    if(require_authorized){
                        dispatch(logoutWithJWT())
                    }else{
                        dispatch(logoutWithJWT(true))
                    }
                    return false;
                }else {
                    dispatch({
                        type: "FETCH_AUTHORIZED_USER_INFO",
                        user: response.data?.data,
                    });
                    localStorage.setItem('user', JSON.stringify(response.data.data));
                }

            }).catch((err) => {
            // console.error(err)
            apiErrorHandler(err, require_authorized)
        })
    }
};

export const getAllUsers = (require_authorized) => {
    return dispatch => {
        axios
            .get(`/api/v1/all-users`)
            .then(response => {
                dispatch({
                    type: "FETCH_USERS",
                    users: response.data.data,
                });
            })
            .catch(err => console.log(err))
    }
};

export const setUserTips = (data, type='') => {
    return dispatch => {
        axios
            .put('/api/me', data)
            .then(response => {
                // dispatch(setFetchMessage(notices.success, 'success'));
                dispatch({
                    type: "SET_USER_TIPS",
                    user: response.data.data,
                    guide: type
                });
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
                dispatch(setFetchMessage(err.response.data.message, 'error'));
            });

    }
}

export const resetGuideAction = (what) => {
    return dispatch => {
        dispatch({
            type: "RESET_USER_GUIDE_DATA",
            payload: what
        })
    }
}

export const updateProfileGeneralInfo = data => {
    return dispatch => {
        axios
            .put('/api/me', {
                fname: data.first_name,
                lname: data.last_name,
                lang: data.lang,

            })
            .then(response => {
                dispatch(setFetchMessage(notices.success, 'success'));
                dispatch({
                    type: "FETCH_AUTHORIZED_USER_INFO",
                    user: response.data.data,
                });
            })
            .catch(err => {
                dispatch(apiErrorHandler(err));
                dispatch(setFetchMessage(err.response.data.message, 'error'));
            });

    }
}

export const updateProfileCompanyInfo = data => {
    return dispatch => {
        axios
            .put('/api/me/company', {
                name: data.company_name,
                address: data.address,
                zip: data.zip,
                city: data.city,
                country: data.country?.value
            })
            .then(response => {
                dispatch({
                    type: "FETCH_AUTHORIZED_USER_INFO",
                    user: response.data.data,
                });
                dispatch(setFetchMessage(notices.success, 'success'));
            })
            .catch(err => {
                // dispatch(apiErrorHandler(err));
                if(err)
                    dispatch(setFetchMessage(err.response.data.message, 'error'));
            });
    }
}


export const changeLanguage = (lang) => {

    return dispatch => {
        dispatch({
            type: "CHANGE_USER_LANGUAGE",
            payload: lang
        })
    }
}
export const unSubscribe = () => {

    return dispatch => {
        axios
            .post('/api/subscription/unsubscribe')
            .then(response => {
                dispatch({
                    type: "USER_UNSUBSCRIBE",
                })
                dispatch(setFetchMessage(response.message, 'success'));
                dispatch(getLoggedUserInfo())
            })
            .catch(err => {
                dispatch(setFetchMessage(err.response.data.message, 'error'));
            });

    }
}