import React, {useState, createContext, useContext, useEffect} from "react";
import ReactDOM from "react-dom"
import {history} from '@src/history';
import "./modal.scss";
// import useModal from "./useModal";
// import {Container} from "reactstrap";

export const ModalContext = createContext();

export const ModalProvider = props => {
    const [openModal, setOpenModal] = useState(false);
    const [modalSize, setModalSize] = useState('md');


    return (
        <ModalContext.Provider value={[openModal, setOpenModal, modalSize, setModalSize]}>
            {props.children}
        </ModalContext.Provider>
    )
};

export const useModalContext = () => useContext(ModalContext);

export const Modal = (props) => {
    const [openModal, setOpenModal, modalSize, setModalSize] = useModalContext();
    const {skin = 'default', children, id, hideClose, closeTo=null, onClose = ()=>{}, size='md', preventBgClose = false} = props;

    // useEffect(() => {
    //     console.log(modalSize)
    // }, [modalSize])

    return openModal[id] ? ReactDOM.createPortal(<div className="modal-area">
        <div className="modal-skin" onClick={() => {
            if(preventBgClose){
                return false;
            }
            if(closeTo)
                setOpenModal({...openModal, [id]: false, [closeTo.id]: false})
            else
                setOpenModal({...openModal, [id]: false})

            onClose()

        }}/>
        <div className={`top_modal ${skin} ${modalSize[props.id] || size}`}>
            {!hideClose &&
            <div className="top_modal__close" onClick={() => {
                if(closeTo)
                    setOpenModal({...openModal, [id]: false, [closeTo.id]: false})
                else
                    setOpenModal({...openModal, [id]: false})

                onClose()

            }}><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="26" height="26" rx="5" fill="#66ADFF"/>
                <path d="M9.46409 16.5354L16.5352 9.46436" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.46409 9.46458L16.5352 16.5356" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            </div>}
            <div className="top_modal__content">
                {children}
            </div>
        </div>
    </div>, document.getElementById('modal-root')) : null
};
