import React, { useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { history } from "../../../../../history";
import { useTranslation } from "react-i18next";
import InputMask from 'react-input-mask';
import { sendPhoneBeforeSignUp } from "../../../../../redux/actions/auth/loginActions";

const SendPhone = (props) => {
    const {t, i18n} = useTranslation();

    const {title, consultation, classBtn, currentRef, footer, btnLabel, onClick} = props;
    const user = useSelector(state => state.authorizedUser.user);
    const consult = useSelector(state => state.authorizedUser.consultation_success);
    const phone = useSelector(state => state.authorizedUser.phone);
    const dispatch = useDispatch();
    const thisRef = useRef(null);
    const inputRef = currentRef ? currentRef : thisRef;

    useEffect(() => {
        console.log(phone)
        if(phone){
            history.push('/sign-up')
        }
    }, [ consult, phone ]);


    return (
        <div className={ `form form--sent-phone ${ footer ? 'form--footer--phone' : '' }` }>
            { title && <p className='form__title'>{ title }</p> }
            <div className="form__content">
                { (user.email === '' && (!consult || !consultation)) && (<>
                    <div className="input-wrapper">
                        <span className="phone-icon"></span>
                        <InputMask mask="+380 99 999 99 99" maskChar=" " className='form-control' ref={ inputRef }
                               placeholder={ "+380" }/>
                    </div>
                    <button className={ `btn btn-primary ${ classBtn }` } onClick={ () => {
                        dispatch(sendPhoneBeforeSignUp({
                            phone: inputRef.current.value,
                            type : consultation ? 'consultation' : 'registration'
                        }))
                    } }>{ btnLabel ? btnLabel : t('Получить консультацию') }
                    </button>
                </>) }
                { (consult && consultation) && <>
                    <div className="success_consultation">
                        <div>
                            Спасибо за интерес к нашему ресурсу.
                        </div>
                        <div>
                            Наш менеджер свяжется с Вами в ближайшее время.
                        </div>
                    </div>
                </> }
                { user.email !== ''  && <button className={ `btn btn-primary ${ classBtn }` } onClick={ () => {
                    history.push('/dashboard')
                } }>{ t("Дашборд") }</button> }
            </div>
        </div>
    )
}


export default SendPhone;