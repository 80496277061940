import React, {PureComponent} from "react";
import classnames from "classnames";
import {connect} from "react-redux";
import Header from "../views/pages/landing/components/header/Header";
import Footer from "../views/pages/landing/components/footer/Footer";

class LandingLayout extends PureComponent {
    state = {
        width: window.innerWidth,
        currentLang: "en",
        appOverlay: false,
        customizer: false,
        currRoute: this.props.location.pathname
    };
    collapsedPaths = [];
    mounted = false;

    updateWidth = () => {
        if (this.mounted) {
            this.setState(prevState => ({
                width: window.innerWidth
            }));
        }
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleCurrentLanguage = lang => {

    };

    handleAppOverlay = value => {
        if (value.length > 0) {
            this.setState({
                appOverlay: true
            });
        } else if (value.length < 0 || value === "") {
            this.setState({
                appOverlay: false
            });
        }
    };

    handleAppOverlayClick = () => {
        this.setState({
            appOverlay: false
        });
    };

    render() {
        return (
            <>
                <div
                    className="wrapper vertical-layout landing-page">
                    <div
                        className={classnames("app-content content", {
                            "show-overlay": this.state.appOverlay === true
                        })}
                        onClick={this.handleAppOverlayClick}
                    >
                        <Header pathname={this.props.location.pathname}/>
                        <div className="content-wrapper">{this.props.children}</div>
                        <Footer/>
                        <div id="modal-root"></div>

                    </div>

                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        app: state.customizer
    };
};
export default connect(mapStateToProps, {})(LandingLayout);
