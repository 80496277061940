import React from "react"
import VerticalLayout from "@src/layouts/VerticalLayout"
import LandingLayout from "@src/layouts/LandingLayout"
import FullLayout from "@src/layouts/FullpageLayout"
import { Scrollbars } from 'react-custom-scrollbars';

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  landing: LandingLayout,
}
const ContextLayout = React.createContext();


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: 'red'
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = props => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

class Layout extends React.Component {
  state = {
    activeLayout: 'vertical',
    width: window.innerWidth,
    lastLayout: null,
    direction: "ltr"
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  handleWindowResize = () => {
    this.updateWidth()
  }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.handleWindowResize)
    }
    this.handleDirUpdate()
  }

  componentDidUpdate() {
    this.handleDirUpdate()
  }

  handleDirUpdate = () => {
    let dir = this.state.direction
    if (dir === "rtl")
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
    else document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
  }
  
  render() {
    const { children } = this.props
    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts["full"],
          VerticalLayout: layouts["vertical"],
          landingLayout: layouts["landing"],
          switchLayout: layout => {
            this.setState({ activeLayout: layout })
          },
          switchDir: dir => {
            this.setState({ direction: dir })
          }
        }}
      >
          {children}
      </ContextLayout.Provider>
    )
  }
}

export { Layout, ContextLayout }
