import axios from "axios"
import {setFetchMessage} from "../notification";
import {getLoggedUserInfo} from "../user";


export const ACTIONS = {
    GET_STRIPE_SESSION: "GET_STRIPE_SESSION",
    SET_IS_FETCHING: "SET_IS_PAYMENT_FETCHING",
    PAY_BY_WALLET: "SUCCESS_PAY_BY_WALLET",
    ERROR_PAY_BY_WALLET: "ERROR_PAY_BY_WALLET",
    DOWNLOAD_PAYMENT_INVOICE: "DOWNLOAD_PAYMENT_INVOICE",
    RESER_PAYMENTS_STATUSES: "RESER_PAYMENTS_STATUSES"
}

export const resetPayments = (payment = 'all') => {
    return dispatch => {
        dispatch(
            {
                type: ACTIONS.RESER_PAYMENTS_STATUSES,
                payment
            }
        )
    }
}


export const initStripePay = (order_id, plan) => {
    let url, params;
    if (order_id) {
        url = `/api/order/${order_id}/pay`;
        params = {method: 'stripe'};
    } else if (plan) {
        url = `/api/subscription/subscribe`;
        params = {plan: plan.value, method: 'stripe'};
    }
    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .post(url, params)
            .then(response => {
                dispatch({
                    type: ACTIONS.GET_STRIPE_SESSION,
                    payload: response.data.data.session_id
                });

            })
            .catch(error => {
                dispatch(setFetchMessage(error.response.data.message, 'error'))
                console.log(['error initStripePay, check your code', error])
            })
    }
}


export const payByWallet = (order_id) => {
    let
        url = `/api/order/${order_id}/pay`,
        params = {method: 'wallet'};

    return dispatch => {
        dispatch(setIsFetching(true))
        axios
            .post(url, params)
            .then(response => {
                if (response.status === 200) {
                    dispatch({
                        type: ACTIONS.PAY_BY_WALLET,
                        payload: 'success'
                    });
                    dispatch(getLoggedUserInfo())
                    if (response.data.hasOwnProperty('message')) {
                        dispatch(setFetchMessage(response.data.message, 'success'));
                    }

                } else {
                    console.error(response)
                    if (response.data.hasOwnProperty('message')) {
                        dispatch(setFetchMessage(response.data.message, 'error'));
                    }
                }

            })
            .catch(error => {
                dispatch(setIsFetching(false))
                dispatch(getLoggedUserInfo())
                dispatch(setFetchMessage(error.response.data.message, 'error'))
                console.error(['error payByWallet, check your code', error])
            })
    }
}

export const payByInvoice = (order_id) => {
    let
        url = `/api/order/${order_id}/pay`,
        params = {method: 'invoice'};
    return dispatch => {
        dispatch(setIsFetching(true))

        axios.post(url, params, {
            responseType: 'blob'
        }).then((response) => {
            dispatch(setIsFetching(false))
            dispatch({
                type: ACTIONS.DOWNLOAD_PAYMENT_INVOICE,
                data: response.data
            })
            dispatch(getLoggedUserInfo())

        }).catch(error => {
            dispatch(setIsFetching(false))
            dispatch(setFetchMessage(error.response.data.message, 'error'))
            console.error(['error payByInvoice, check your code', error])
        })
    }
};

export const setIsFetching = (action) => {
    return dispatch => {
        dispatch({
            type: ACTIONS.SET_IS_FETCHING,
            payload: action
        });
    }

}
