import React, {useState} from "react"
import {Col, Collapse, Container, Nav, Navbar, NavbarToggler, Row} from "reactstrap"
import {connect} from "react-redux"
import NavbarUser from "./NavbarUser"
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Navbars = props => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <header id='main-header'>
            <Container>
                <Row>
                    <Col>
                        <Navbar bg="light" variant="light" expand="lg" className="custom-navbar">
                            <div className="nav-bar-toggle d-lg-none" style={{marginLeft: '15px'}}>
                                <button type="button" className="toggle-menu-btn" onClick={toggle}>
                                    {
                                        !isOpen && <svg width="29" height="16" viewBox="0 0 29 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 15C0 15.5523 0.447715 16 1 16H28C28.5523 16 29 15.5523 29 15C29 14.4477 28.5523 14 28 14H1C0.447716 14 0 14.4477 0 15Z" fill="currentColor"/>
                                        <path d="M0 8C0 8.55228 0.447715 9 1 9H28C28.5523 9 29 8.55228 29 8C29 7.44772 28.5523 7 28 7H1C0.447716 7 0 7.44772 0 8Z" fill="currentColor"/>
                                        <path d="M0 1C0 1.55228 0.447715 2 1 2H28C28.5523 2 29 1.55228 29 1C29 0.447715 28.5523 0 28 0H1C0.447716 0 0 0.447715 0 1Z" fill="currentColor"/>
                                    </svg>
                                    }
                                    {
                                        isOpen && <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.82868 12.728L13.314 4.24268" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M4.82868 4.24262L13.314 12.7279" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    }
                                </button>
                            </div>
                            <div className="brand-box">
                                <NavLink to='/'>
                                    <svg width="182" height="32" viewBox="0 0 182 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M54.2793 16.9413C53.7446 16.2336 53.0212 15.7304 52.1091 15.4316C52.7696 15.0856 53.2886 14.5981 53.666 13.969C54.0434 13.34 54.2321 12.6166 54.2321 11.7988C54.2321 10.4621 53.7053 9.41635 52.6517 8.6615C51.598 7.89092 50.0962 7.50563 48.1462 7.50563H40.4089V24.018H48.6179C50.7252 24.018 52.3293 23.6327 53.4301 22.8621C54.5309 22.0758 55.0813 20.9671 55.0813 19.5361C55.0813 18.4981 54.814 17.6332 54.2793 16.9413ZM43.4755 14.4644V9.91172H47.8159C48.8853 9.91172 49.703 10.1083 50.2692 10.5014C50.851 10.8789 51.142 11.445 51.142 12.1999C51.142 12.939 50.851 13.5051 50.2692 13.8983C49.703 14.2757 48.8853 14.4644 47.8159 14.4644H43.4755ZM51.9912 19.2294C51.9912 20.8177 50.8039 21.6119 48.4292 21.6119H43.4755V16.8469H48.4292C50.8039 16.8469 51.9912 17.6411 51.9912 19.2294Z" fill="#4C5D63"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M69.8656 18.6633C69.8971 18.2858 69.9128 17.9949 69.9128 17.7905C69.9128 16.5009 69.6376 15.3687 69.0872 14.3936C68.5525 13.4029 67.7976 12.6402 66.8226 12.1055C65.8633 11.5551 64.7704 11.2799 63.5437 11.2799C62.3014 11.2799 61.1848 11.5551 60.1941 12.1055C59.2034 12.6559 58.4249 13.4265 57.8588 14.4172C57.3084 15.3923 57.0332 16.4931 57.0332 17.7197C57.0332 18.9621 57.3162 20.0786 57.8824 21.0694C58.4642 22.0444 59.282 22.8071 60.3356 23.3575C61.3893 23.9079 62.608 24.1831 63.9919 24.1831C65.077 24.1831 66.052 24.0101 66.917 23.6642C67.7819 23.3182 68.4975 22.8071 69.0636 22.1309L67.4831 20.3145C66.5867 21.2266 65.4466 21.6827 64.0627 21.6827C62.9776 21.6827 62.0655 21.4153 61.3264 20.8806C60.603 20.3302 60.1548 19.5911 59.9818 18.6633H69.8656ZM61.1377 14.488C61.7982 13.9219 62.6002 13.6388 63.5437 13.6388C64.503 13.6388 65.3051 13.9219 65.9498 14.488C66.5946 15.0541 66.9799 15.7933 67.1057 16.7054H59.9582C60.0997 15.7775 60.4929 15.0384 61.1377 14.488Z" fill="#4C5D63"/>
                                        <path d="M73.6415 23.7821C74.6165 24.0494 75.6152 24.1831 76.6373 24.1831C78.3987 24.1831 79.7904 23.8293 80.8126 23.1216C81.8348 22.4139 82.3459 21.4704 82.3459 20.2909C82.3459 19.3945 82.11 18.7026 81.6382 18.2151C81.1822 17.7276 80.6318 17.3816 79.987 17.1772C79.3422 16.9727 78.5166 16.7919 77.5101 16.6346C76.5037 16.4616 75.7567 16.2808 75.2692 16.0921C74.7974 15.8876 74.5615 15.5495 74.5615 15.0777C74.5615 14.6531 74.7817 14.315 75.222 14.0634C75.6781 13.7961 76.3386 13.6624 77.2035 13.6624C78.5402 13.6624 79.7432 13.9769 80.8126 14.6059L81.9449 12.365C81.3945 12.0347 80.6868 11.7753 79.8219 11.5865C78.9569 11.3821 78.092 11.2799 77.2271 11.2799C75.5287 11.2799 74.1762 11.6416 73.1698 12.365C72.179 13.0727 71.6836 14.0241 71.6836 15.2193C71.6836 16.1314 71.9195 16.8469 72.3913 17.3659C72.8788 17.8691 73.445 18.2229 74.0897 18.4274C74.7502 18.6318 75.5994 18.8127 76.6373 18.9699C77.6281 19.1272 78.3436 19.2923 78.784 19.4653C79.24 19.6383 79.468 19.9449 79.468 20.3853C79.468 21.3131 78.5874 21.777 76.8261 21.777C76.0555 21.777 75.2692 21.6669 74.4671 21.4468C73.6808 21.2109 73.0046 20.9042 72.4385 20.5268L71.3062 22.7678C71.8881 23.1766 72.6665 23.5148 73.6415 23.7821Z" fill="#4C5D63"/>
                                        <path d="M91.1835 23.9708C91.6867 23.8293 92.1114 23.617 92.4573 23.3339L91.6317 21.2345C91.1914 21.5805 90.641 21.7534 89.9805 21.7534C89.4143 21.7534 88.9819 21.5962 88.6831 21.2817C88.3843 20.9514 88.2349 20.4796 88.2349 19.8663V13.8747H91.6081V11.5158H88.2349V8.63791H85.2862V11.5158H83.2104V13.8747H85.2862V19.9371C85.2862 21.321 85.6637 22.3746 86.4185 23.098C87.1734 23.8214 88.2427 24.1831 89.6266 24.1831C90.177 24.1831 90.696 24.1123 91.1835 23.9708Z" fill="#4C5D63"/>
                                        <path d="M98.2326 7.50563H95.166V24.018H106.866V21.4232H98.2326V7.50563Z" fill="#4C5D63"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M118.178 12.6245C117.203 11.7281 115.787 11.2799 113.931 11.2799C112.894 11.2799 111.911 11.4136 110.983 11.6809C110.055 11.9482 109.261 12.3335 108.6 12.8368L109.756 14.9834C110.228 14.5902 110.802 14.2836 111.478 14.0634C112.17 13.8432 112.87 13.7332 113.578 13.7332C114.616 13.7332 115.394 13.969 115.913 14.4408C116.448 14.9126 116.715 15.5888 116.715 16.4695V16.6582H113.46C111.651 16.6582 110.322 17.0042 109.473 17.6961C108.64 18.3723 108.223 19.2766 108.223 20.4089C108.223 21.1323 108.412 21.7849 108.789 22.3668C109.182 22.9329 109.733 23.3811 110.44 23.7113C111.148 24.0259 111.974 24.1831 112.917 24.1831C113.861 24.1831 114.663 24.0416 115.323 23.7585C115.999 23.4597 116.518 23.0351 116.88 22.4847V24.018H119.664V16.6346C119.664 14.8418 119.168 13.5051 118.178 12.6245ZM115.488 21.5411C114.922 21.8714 114.27 22.0365 113.53 22.0365C112.776 22.0365 112.186 21.8792 111.761 21.5647C111.337 21.2502 111.124 20.8256 111.124 20.2909C111.124 19.1744 111.974 18.6161 113.672 18.6161H116.715V20.0786C116.479 20.7077 116.07 21.1952 115.488 21.5411Z" fill="#4C5D63"/>
                                        <path d="M132.836 24.018H135.643V11.4214H132.695V17.7905C132.695 19.0171 132.38 19.9607 131.751 20.6212C131.138 21.2659 130.304 21.5883 129.251 21.5883C128.307 21.5883 127.576 21.3131 127.057 20.7627C126.554 20.1966 126.302 19.3474 126.302 18.2151V11.4214H123.353V18.6161C123.353 20.456 123.833 21.8478 124.792 22.7914C125.767 23.7192 127.096 24.1831 128.779 24.1831C129.597 24.1831 130.359 24.0337 131.067 23.7349C131.775 23.4204 132.365 22.9801 132.836 22.4139V24.018Z" fill="#4C5D63"/>
                                        <path d="M150.473 12.6716C149.514 11.7438 148.24 11.2799 146.652 11.2799C145.724 11.2799 144.89 11.4293 144.151 11.7281C143.412 12.0269 142.799 12.4672 142.311 13.0491V11.4214H139.504V24.018H142.453V17.6489C142.453 16.4223 142.767 15.4866 143.396 14.8418C144.025 14.1813 144.882 13.8511 145.967 13.8511C146.927 13.8511 147.666 14.1342 148.185 14.7003C148.704 15.2507 148.963 16.0763 148.963 17.1772V24.018H151.912V16.7997C151.912 14.9755 151.432 13.5995 150.473 12.6716Z" fill="#4C5D63"/>
                                        <path d="M158.032 23.3575C159.07 23.9079 160.242 24.1831 161.547 24.1831C162.774 24.1831 163.851 23.9315 164.779 23.4283C165.722 22.9093 166.438 22.178 166.925 21.2345L164.661 19.9135C164.299 20.4954 163.843 20.9357 163.293 21.2345C162.758 21.5175 162.168 21.6591 161.523 21.6591C160.407 21.6591 159.487 21.3052 158.763 20.5976C158.056 19.8742 157.702 18.9149 157.702 17.7197C157.702 16.5245 158.064 15.5731 158.787 14.8654C159.51 14.142 160.423 13.7803 161.523 13.7803C162.168 13.7803 162.758 13.9297 163.293 14.2285C163.843 14.5116 164.299 14.9441 164.661 15.5259L166.925 14.2049C166.454 13.2614 165.746 12.538 164.802 12.0347C163.874 11.5315 162.789 11.2799 161.547 11.2799C160.226 11.2799 159.047 11.5551 158.009 12.1055C156.986 12.6559 156.184 13.4186 155.603 14.3936C155.021 15.3687 154.73 16.4773 154.73 17.7197C154.73 18.9621 155.021 20.0786 155.603 21.0694C156.184 22.0444 156.994 22.8071 158.032 23.3575Z" fill="#4C5D63"/>
                                        <path d="M180.328 12.6716C179.369 11.7438 178.095 11.2799 176.507 11.2799C175.626 11.2799 174.824 11.4214 174.1 11.7045C173.393 11.9718 172.795 12.365 172.308 12.8839V6.51489H169.359V24.018H172.308V17.6489C172.308 16.4223 172.622 15.4866 173.251 14.8418C173.88 14.1813 174.737 13.8511 175.822 13.8511C176.782 13.8511 177.521 14.1342 178.04 14.7003C178.559 15.2507 178.818 16.0763 178.818 17.1772V24.018H181.767V16.7997C181.767 14.9755 181.287 13.5995 180.328 12.6716Z" fill="#4C5D63"/>
                                        <path d="M8.29497 28.2558C7.87252 27.4637 7.92533 26.566 8.34778 25.8267L11.8858 19.9124L10.0904 13.2587C9.87918 12.4138 10.0904 11.5161 10.7241 10.8824C11.3578 10.2487 12.2027 9.98468 13.0476 10.1959L19.754 11.7273L25.5628 7.97802C26.3021 7.50276 27.1998 7.44995 27.9919 7.8196C28.3615 7.97802 28.6784 8.29486 28.8896 8.6117L29.9457 7.55557C32.3221 5.17926 30.7379 1.11314 27.3582 0.954721L4.01763 0.00419928C1.74694 -0.101414 -0.101301 1.79963 0.00431235 4.07032L1.16606 27.4637C1.32448 30.7905 5.3906 32.3747 7.76691 29.9984L8.77024 28.9951C8.55901 28.7311 8.40059 28.5199 8.29497 28.2558Z" fill="#419AFF"/>
                                        <path d="M27.1998 18.0114L26.6717 12.0971C26.6189 11.3578 25.774 10.9353 25.1403 11.3578L20.1765 14.579L14.4205 13.2588C13.6812 13.1004 13.0475 13.7341 13.2588 14.4734L14.7902 20.1765L11.7274 25.2459C11.3577 25.8796 11.7802 26.7245 12.5195 26.7773L18.4338 27.0942L22.2887 31.5828C22.764 32.1636 23.7145 32.0052 23.9785 31.2659L26.0908 25.774L31.5299 23.4505C32.2164 23.1337 32.3748 22.236 31.7939 21.7607L27.1998 18.0114Z" fill="#7EBAFF"/>
                                    </svg>
                                </NavLink>
                            </div>

                            {props.user.email != '' && (
                                <NavbarUser
                                    confirm={props.confirm}
                                    userName={`${props.user.fname}`}
                                    loggedInWith={
                                        props.user !== undefined
                                            ? props.loggedInWith
                                            : null
                                    }
                                />
                            )}
                            <Collapse isOpen={isOpen} navbar>
                                <Nav className="mr-auto ml-0" style={{marginLeft: '0', marginRight: 'auto'}}>
                                    <li>

                                        <NavLink to='/dashboard' activeClassName='in-page'>
                                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1 5.9L7.3 1L13.6 5.9V13.6C13.6 13.9713 13.4525 14.3274 13.1899 14.5899C12.9274 14.8525 12.5713 15 12.2 15H2.4C2.0287 15 1.6726 14.8525 1.41005 14.5899C1.1475 14.3274 1 13.9713 1 13.6V5.9Z"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M5.2002 15V8H9.4002V15" stroke="#5C7279" strokeLinecap="round"
                                                      strokeLinejoin="round"/>
                                            </svg>
                                            {t("Дашборд")}
                                        </NavLink>
                                    </li>
                                    <li>

                                        <NavLink to='/products' activeClassName='in-page'>
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M5.25033 12.8334C5.57249 12.8334 5.83366 12.5722 5.83366 12.2501C5.83366 11.9279 5.57249 11.6667 5.25033 11.6667C4.92816 11.6667 4.66699 11.9279 4.66699 12.2501C4.66699 12.5722 4.92816 12.8334 5.25033 12.8334Z"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M11.6663 12.8334C11.9885 12.8334 12.2497 12.5722 12.2497 12.2501C12.2497 11.9279 11.9885 11.6667 11.6663 11.6667C11.3442 11.6667 11.083 11.9279 11.083 12.2501C11.083 12.5722 11.3442 12.8334 11.6663 12.8334Z"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path
                                                    d="M0.583008 0.583252H2.91634L4.47967 8.39409C4.53302 8.66265 4.67912 8.9039 4.89241 9.07559C5.1057 9.24729 5.37258 9.3385 5.64634 9.33325H11.3163C11.5901 9.3385 11.857 9.24729 12.0703 9.07559C12.2836 8.9039 12.4297 8.66265 12.483 8.39409L13.4163 3.49992H3.49967"
                                                    stroke="#5C7279" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                            {t("Мои продукты и заказы")}
                                        </NavLink>
                                    </li>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>

        </header>
    )
}

const mapStateToProps = state => {
    return {
        user: state.authorizedUser.user,
    }
}

export default connect(mapStateToProps, {})(Navbars);
