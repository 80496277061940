import React from 'react';
import {useTranslation} from "react-i18next";

const Image = (props) => {
    const {slug, alt, className} = props;
    const { t, i18n } = useTranslation();
    const image = {src: '', srcSet: ''};

    try{
        image.src = require(`@assets/img/landing/${slug}`)?.default
    }catch (ex){
        console.warn('url => ', ex)
        image.src = false;
    }

    try{
        image.srcSet = `${require(`@assets/img/landing/2x-${slug}`)?.default} 2x`
    }catch (ex){
        console.warn('url2x => ', ex)
        image.srcSet = false;
    }

    return (
        <img {...image} alt={alt} className={className}/>
    )
};

export default Image;