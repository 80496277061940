import React from 'react';
import Image from "../image/Image";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import { NavHashLink } from 'react-router-hash-link';
import {useTranslation} from "react-i18next";
import LanguageSwitcher from "../switcher/Switcher";

const Header = ({pathname}) => {
    const user = useSelector(state => state.authorizedUser.user);
    const { t, i18n } = useTranslation();

    return (
        <header className={`header ${pathname==='/'?'header--transparent' : ''}`}>
            {/*<LanguageSwitcher/>*/}
            <Container>
                <Row>
                    <Col md={12}>
                        <Navbar expand="lg">
                            <NavLink to='/' className='navbar-brand'>
                                <Image slug='logo.png' alt='logo' className='logo'/>
                            </NavLink>
                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                            <Navbar.Collapse id="basic-navbar-nav" className="nav-bar">
                                <Nav className="ms-auto justify-content-end">
                                    <NavHashLink to="/" className='nav-link'>{t('Главная')}</NavHashLink>
                                    <NavHashLink to="/#solutions" className='nav-link'>{t('Как это работает?')}</NavHashLink>
                                    <NavHashLink to="/#faq" className='nav-link'>{t('Вопрос / Ответ')}</NavHashLink>
                                    {user.email === '' && (<>
                                    <NavHashLink to='/login' className='nav-link login'>{t('Войти')}</NavHashLink>
                                    <NavHashLink to='/sign-up' className="btn btn-primary rocket">{t('Начать сейчас! ')}</NavHashLink>
                                        </>)}
                                    {user.email !== '' && (
                                        <NavHashLink to="/dashboard" className="btn btn-primary rocket">{t('Дашборд')} </NavHashLink>)}
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </header>
    )
};



export default Header;